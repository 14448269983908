import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

var firebaseConfig = {
  apiKey: "AIzaSyCco7Z9fDwLyWCXF2uQND9Fcn9IVIiEMeg",
	authDomain: "push-notifications-vue.firebaseapp.com",
	projectId: "push-notifications-vue",
	storageBucket: "push-notifications-vue.appspot.com",
	messagingSenderId: "519060094885",
	appId: "1:519060094885:web:afad187516792666cd724c",
	measurementId: "G-MW0JE0P7HX"
};

firebase.initializeApp(firebaseConfig)

export default firebase.messaging()